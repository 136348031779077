import React, { useEffect } from 'react';
import { Col, message, Row, Spin } from 'antd';
import {
  GET_USER_BY_ID,
  LoggedInUser,
  ProfileToEdit,
  ProfileUser,
  Query,
  QueryGetUserByIdArgs,
  ShareProfile,
  User,
  UserRole,
  Profile as IProfile,
  TemplateToEdit,
} from '../../../graphql';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import {
  EditProfileModal,
  EditTemplateModal,
  ShareProfileModal,
  TemplateA,
  TemplateB,
  TemplateC,
} from '.';
import websiteIcon from '../../../assets/images/websiteIcon.png';
import facebookIcon from '../../../assets/images/facebookIcon.png';
import instagramIcon from '../../../assets/images/instagramIcon.png';
import xIcon from '../../../assets/images/xIcon.png';
import behanceIcon from '../../../assets/images/behanceIcon.png';
import linkedInIcon from '../../../assets/images/linkedInIcon.png';
import tiktokIcon from '../../../assets/images/tiktokIcon.png';
import youtubeIcon from '../../../assets/images/youtubeIcon.png';
import sms from '../../../assets/images/sms.png';
import { colors, openUrlInNewTab } from '../../../utils';
import heart from '../../../assets/images/heart.png';
import { format } from 'date-fns';
import calendar from '../../../assets/images/calendar.png';
import locationIcon from '../../../assets/images/location.png';
import cake from '../../../assets/images/cake.png';
import { OutlinedButton, PrimaryButton, Text } from '../../../components';
import flash from '../../../assets/images/flash.png';
import teacher from '../../../assets/images/teacher.png';
import briefcase from '../../../assets/images/briefcase.png';
import global from '../../../assets/images/global.png';
import {
  EditOutlined,
  LayoutOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';

export default function Profile() {
  const location = useLocation();
  const loggedInUser = useReactiveVar(LoggedInUser);
  const profileToEdit = useReactiveVar(ProfileToEdit);
  const share = useReactiveVar(ShareProfile);
  const user = useReactiveVar(ProfileUser);
  const templateToEdit = useReactiveVar(TemplateToEdit);
  const { id } = useParams();
  const userId = id || loggedInUser?.id;
  const [getUserById, { loading, refetch }] = useLazyQuery<
    Query,
    QueryGetUserByIdArgs
  >(GET_USER_BY_ID);

  const handleUser = () => {
    if (userId) {
      getUserById({ variables: { userId } }).then((res) =>
        ProfileUser(res.data?.getUserById),
      );
    } else {
      ProfileUser(loggedInUser);
    }
  };

  useEffect(() => {
    ProfileUser(undefined);
    handleUser();

    return () => {
      ProfileUser(undefined);
    };
  }, [loggedInUser, location.pathname]);

  const getProfile = () => {
    if (user?.role) {
      switch (
        user.profile?.[user.role.toLowerCase() as keyof IProfile]?.template
      ) {
        case 'A':
          return <TemplateA />;
        case 'B':
          return <TemplateB />;
        case 'C':
          return <TemplateC />;
        default:
          return <TemplateA />;
      }
    }
    return <></>;
  };

  return loading ? (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  ) : !!user?.profile ? (
    <>
      {getProfile()}
      {profileToEdit?.id && (
        <EditProfileModal
          onSuccess={() =>
            refetch({ userId: user?.id }).then((res) =>
              ProfileUser(res.data?.getUserById),
            )
          }
          handleClose={() => ProfileToEdit({})}
        />
      )}
      {share && (
        <ShareProfileModal
          userId={user.id}
          handleClose={() => ShareProfile(false)}
        />
      )}
      {templateToEdit && (
        <EditTemplateModal
          onSuccess={() =>
            refetch({ userId: user?.id }).then((res) =>
              ProfileUser(res.data?.getUserById),
            )
          }
          handleClose={() => TemplateToEdit(false)}
        />
      )}
    </>
  ) : (
    <></>
  );
}

export type Tab =
  | 'overview'
  | 'interview'
  | 'posts'
  | 'photos'
  | 'videos'
  | 'about';

export type TabItem = {
  label: Tab;
  content: JSX.Element;
  show: boolean;
};

export const urls = [
  { key: 'websiteUrl', icon: websiteIcon },
  { key: 'facebookUrl', icon: facebookIcon },
  { key: 'instagramUrl', icon: instagramIcon },
  { key: 'xUrl', icon: xIcon },
  { key: 'behanceUrl', icon: behanceIcon },
  { key: 'linkedInUrl', icon: linkedInIcon },
  { key: 'tiktokUrl', icon: tiktokIcon },
  { key: 'youtubeUrl', icon: youtubeIcon },
];

const getJob = ({ profile, role }: User) => {
  if (role !== UserRole.Ambassador) {
    if (profile?.jobTitle) {
      if (profile?.companyName) {
        return `${profile?.jobTitle} at ${profile?.companyName}`;
      }
      return profile?.jobTitle;
    }
    if (profile?.companyName) {
      return profile.companyName;
    }
  }
  return null;
};

const getDob = (dob: string | null | undefined) => {
  if (dob) {
    return format(new Date(Number(dob)), 'PP');
  }
  return null;
};

export const additionalDetails = (user: User) => [
  {
    label: 'email',
    value: user?.hideEmail ? null : user?.email,
    icon: sms,
    onClick: () =>
      openUrlInNewTab(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${user?.email}`,
      ),
  },
  {
    label: 'preferred gender pronouns',
    value: user?.profile?.pronouns,
    icon: heart,
  },
  {
    label: 'join date',
    value: user?.createdAt ? format(new Date(user?.createdAt), 'PP') : '',
    icon: calendar,
  },
  {
    label: 'currently based',
    value: user?.profile?.location
      ? JSON.parse(user.profile.location).label
      : '',
    icon: locationIcon,
  },
  { label: 'date of birth', value: user.profile?.hideDob ? null : getDob(user.profile?.dob), icon: cake },
  {
    label: 'Ocean Activities',
    value:
      user?.role === UserRole.Advocate &&
      user?.profile?.advocate?.activities ? (
        <Row gutter={[6, 6]} style={{ marginTop: 4 }}>
          {user?.profile?.advocate?.activities.map((activity) => (
            <Col key={activity}>
              <Row
                style={{
                  borderRadius: 6,
                  border: `1px solid ${colors.blue6}`,
                  padding: '2px 11px',
                }}
              >
                <Text variant={'smMedium'} color={'blue6'}>
                  {activity}
                </Text>
              </Row>
            </Col>
          ))}
        </Row>
      ) : null,
    icon: flash,
  },
  {
    label: 'School',
    value:
      user?.role === UserRole.Ambassador
        ? user?.profile?.ambassador?.school
        : null,
    icon: teacher,
  },
  {
    label: 'job',
    value: getJob(user),
    icon: briefcase,
  },
  {
    label: 'website',
    value:
      user?.role === UserRole.Ambassador ? null : user?.profile?.websiteUrl,
    icon: global,
    onClick: () => openUrlInNewTab(user?.profile?.websiteUrl as string),
  },
];

export const SendMessageButton = ({
  style,
}: {
  style?: React.CSSProperties;
}) => (
  <PrimaryButton
    style={{ textAlign: 'right', ...style }}
    onClick={() => message.open({ content: 'Coming soon!' })}
  >
    Send message
  </PrimaryButton>
);

export const EditProfileButton = ({
  style,
}: {
  style?: React.CSSProperties;
}) => (
  <OutlinedButton
    height={32}
    fontSize={14}
    icon={<EditOutlined style={{ color: colors.blue6 }} />}
    style={{ textAlign: 'right', ...style }}
    onClick={() => ProfileToEdit({ id: ProfileUser()?.id })}
  >
    Edit profile
  </OutlinedButton>
);

export const ShareProfileButton = ({
  style,
}: {
  style?: React.CSSProperties;
}) => (
  <PrimaryButton
    height={32}
    fontSize={14}
    icon={<ShareAltOutlined />}
    style={{ textAlign: 'right', ...style }}
    onClick={() => ShareProfile(true)}
  >
    Share profile
  </PrimaryButton>
);

export const EditTemplateButton = ({
  style,
}: {
  style?: React.CSSProperties;
}) => (
  <OutlinedButton
    height={32}
    fontSize={14}
    icon={<LayoutOutlined />}
    style={{
      border: `1px solid ${colors.blue6}`,
      textAlign: 'right',
      ...style,
    }}
    onClick={() => TemplateToEdit(true)}
  >
    Customize layout
  </OutlinedButton>
);
