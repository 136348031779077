import React, { useEffect, useState } from 'react';
import { Col, Divider, Layout, message, Row } from 'antd';
import Icon from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../utils';
import { Text } from './Text';
import { logout, setSidebarOpen, appState } from '../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import '../assets/scss/Sidebar.scss';
import logo_svg from '../assets/svg/logo-svg.svg';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import CloseSidebar from '../assets/svg/CloseSidebar';
import {
  EventsIcon,
  FeedIcon,
  FundingsIcon,
  GroupsIcon,
  HomeIcon,
  LogoutIcon,
  MembersIcon,
  ProfileIcon,
} from './Icons';
import { useReactiveVar } from '@apollo/client';
import { LoggedInUser } from '../graphql';

const { Sider } = Layout;

type Item = {
  key: string;
  title: string;
  to: string;
  icon: () => JSX.Element;
  isButton?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  hide?: boolean;
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useReactiveVar(LoggedInUser);
  const [selectedKey, setSelectedKey] = useState<string>('home');

  useEffect(() => {
    if (location.pathname.includes('profile')) setSelectedKey('profile');
    else if (location.pathname.includes('feed')) setSelectedKey('feed');
    else if (location.pathname.includes('members')) setSelectedKey('members');
    else if (location.pathname.includes('groups')) setSelectedKey('groups');
    else if (location.pathname.includes('events')) setSelectedKey('events');
    // else if (location.pathname.includes('fundings')) setSelectedKey('fundings');
    else setSelectedKey('home');
  }, [location.pathname]);

  const items: Item[] = [
    {
      key: 'home',
      title: 'Home',
      to: '/',
      icon: HomeIcon,
    },
    {
      key: 'members',
      title: 'OCL Members',
      to: '/members',
      icon: MembersIcon,
    },
    {
      key: 'feed',
      title: 'Share Your Story',
      to: '/feed',
      icon: FeedIcon,
    },
    {
      key: 'events',
      title: 'Events',
      to: '/events',
      icon: EventsIcon,
    },
    {
      key: 'groups',
      title: 'Groups',
      to: '/groups',
      icon: GroupsIcon,
    },
    // {
    //   key: 'fundings',
    //   title: 'Fundings',
    //   to: '/fundings',
    //   icon: FundingsIcon,
    //   disabled: true,
    // },
    // {
    //   key: 'profile',
    //   title: 'My profile',
    //   to: '/profile',
    //   icon: profileIcon,
    // },
  ];

  const bottomItems: Item[] = [
    {
      key: 'profile',
      title: 'My profile',
      to: '/profile',
      icon: ProfileIcon,
      hide: !user?.id,
    },
    {
      key: 'logout',
      title: 'Logout',
      to: '/',
      onClick: () => {
        dispatch(logout());
        navigate('/');
      },
      icon: LogoutIcon,
      hide: !user?.id,
    },
  ];

  const { sidebarOpen } = useSelector(appState);

  // ------------GetBreakpointWidth_Logic-------------
  const { xs, md, sm } = useBreakpoint();

  const getBreakpointWidth = () => {
    return 270 + 12 + 12;
  };

  const [BreakpointWidth, setBreakpointWidth] = useState(getBreakpointWidth());

  useEffect(() => {
    if (md) {
      dispatch(setSidebarOpen(true)); // screenSize > 960
    } else {
      dispatch(setSidebarOpen(false)); // screenSize < 960
    }
    setBreakpointWidth(getBreakpointWidth());
  }, [dispatch, md]);
  // ------------/GetBreakpointWidth_Logic-------------

  const renderMenuItem = (item: Item) => (
    <Col
      key={item.key}
      span={24}
      className={item.disabled || item.key === selectedKey ? '' : 'menu-item'}
      style={{
        borderRadius: 8,
        background: item.key === selectedKey ? colors.blue6 : 'white',
        cursor: item.disabled ? 'not-allowed' : 'pointer',
        padding: '9px 14px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        ...(!sidebarOpen && { justifyContent: 'center' }),
      }}
      onClick={() => {
        if (!item.disabled) {
          if (item.onClick) item.onClick();
          else navigate(item.to);
          setSelectedKey(item.key);
          if (!sm) {
            dispatch(setSidebarOpen(false));
          }
        }
      }}
    >
      <Icon
        component={() => <item.icon />}
        className={`icon ${selectedKey === item.key ? 'selected' : ''}`}
      />
      {sidebarOpen && (
        <Row style={{ gap: 4 }}>
          <Text
            className={'menu-text'}
            color={selectedKey === item.key ? 'white' : 'black6'}
            fontSize={14}
            fontWeight={500}
            style={{
              transition: 'all 0.3s 0s ease-out',
              textWrap: 'nowrap',
            }}
          >
            {item.title}
          </Text>
          {item.disabled && (
            <Text variant={'baseMedium'} color={'black3'}>
              (soon)
            </Text>
          )}
        </Row>
      )}
    </Col>
  );

  return (
    <>
      <Sider
        width={BreakpointWidth}
        collapsed={!sidebarOpen}
        collapsible={true}
        style={{
          backgroundColor: 'white',
          height: sm ? 'calc(100vh - 24px)' : '100vh',
          borderRadius: '12px',
          padding: sidebarOpen ? 16 : 8,
          border: `1px solid ${colors.black1}`,
          zIndex: 10,
          position: 'fixed',
          transition: 'all 0.3s 0s ease-in-out',
          ...(!sm && !sidebarOpen && { display: 'none' }),
        }}
        className={
          'sidebar-container ' +
          (sidebarOpen ? ' ' : sm ? ' sidebar_is_close' : '')
        }
        trigger={null}
      >
        <div
          className="sidebar-wrap"
          style={{
            backgroundColor: colors.colorWhite,
          }}
        >
          <Row className="sidebar-item-wrap1" style={{ gap: '20px' }}>
            <Col className="col-logo" style={{ borderColor: colors.black1 }}>
              <div className="logo-wrap" onClick={() => navigate('/')}>
                <img
                  src={logo_svg}
                  alt={''}
                  style={{ height: '38px', width: '38px' }}
                />
                <Text
                  color={'black10'}
                  fontSize={18}
                  fontWeight={600}
                  style={{ textWrap: 'nowrap' }}
                >
                  Ocean Culture Life
                </Text>
              </div>
              <div
                className="close-icon"
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch(setSidebarOpen(!sidebarOpen))}
              >
                <CloseSidebar />
              </div>
            </Col>
            <Col className="sidebar-link-wrap" style={{ width: '100%' }}>
              <Row gutter={[0, 8]}>{items.map(renderMenuItem)}</Row>
            </Col>
          </Row>
          {!sidebarOpen && (
            <div
              style={{
                position: 'absolute',
                right: '-10px',
                top: '28px',
                padding: '2px 4px',
                borderRadius: 4,
                borderColor: colors.black1,
                background: colors.colorWhite,
                boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.07)',
                cursor: 'pointer',
              }}
              onClick={() => dispatch(setSidebarOpen(true))}
            >
              <img
                src={require('../assets/images/sidebarOpen.png')}
                alt={''}
                style={{ height: 12, width: 12 }}
              />
            </div>
          )}
        </div>

        <Row
          className="sidebar-bottom"
          style={{
            position: 'relative',
            bottom: 8,
            left: 0,
            marginTop: 'auto',
            width: '100%',
          }}
          gutter={[0, 8]}
        >
          {!sm && <Divider />}
          {bottomItems.filter((item) => !item.hide).map(renderMenuItem)}
        </Row>
      </Sider>
      {!sm && (
        <div className="mobile-bottom-header">
          <Row gutter={[32, 0]} className={'mobile-menu'}>
            {items.map((item) => (
              <Col
                key={item.key}
                style={{
                  gap: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (item.disabled) message.open({ content: 'Coming soon!' });
                  else navigate(item.to);
                }}
              >
                <Icon
                  component={() => <item.icon />}
                  className={`icon ${
                    selectedKey === item.key ? 'selected' : ''
                  }`}
                />
                {selectedKey === item.key && (
                  <div
                    style={{
                      backgroundColor: colors.blue6,
                      height: 6,
                      width: 6,
                      borderRadius: 6,
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};

export default Sidebar;
