import { Col, ConfigProvider, Form, message, Modal, Row, Tabs } from 'antd';
import { OutlinedButton, PrimaryButton, Text } from '../../../components';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useModalWidth } from '../../../hooks';
import {
  GET_ALL_USERS,
  GET_USERS_BY_DISTANCE,
  KeepOldImages,
  Mutation,
  MutationUpdateUserArgs,
  MutationUploadFileArgs,
  MutationUploadMultipleFilesArgs,
  ProfileToEdit,
  ProfileUser,
  UPDATE_USER,
  UpdateProfileInput,
  UPLOAD_FILE,
  UPLOAD_MULTIPLE_FILES,
  uploadClient,
  UserRole,
  UserStatus,
  VideoUrls,
} from '../../../graphql';
import dayjs from 'dayjs';
import {
  EditAdditional,
  EditAdvocate,
  EditAmbassador,
  EditBasic,
  EditGuardian,
  EditStoryteller,
} from './index';

type Props = {
  onSuccess: () => void;
  handleClose: () => void;
};

export default function EditProfileModal({ onSuccess, handleClose }: Props) {
  const { width } = useModalWidth();
  const [form] = Form.useForm();
  const user = useReactiveVar(ProfileUser);
  const profileToEdit = useReactiveVar(ProfileToEdit);
  const videoUrls = useReactiveVar(VideoUrls);
  const keepOldImages = useReactiveVar(KeepOldImages);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [uploadMultipleFiles, { loading: uploadingMultiple }] = useMutation<
    Mutation,
    MutationUploadMultipleFilesArgs
  >(UPLOAD_MULTIPLE_FILES, { client: uploadClient });
  const [updateUser, { loading }] = useMutation<
    Mutation,
    MutationUpdateUserArgs
  >(UPDATE_USER);

  const getInterview = () => {
    switch (user?.role) {
      case UserRole.Storyteller:
        return <EditStoryteller form={form} />;
      case UserRole.Guardian:
        return <EditGuardian form={form} />;
      case UserRole.Advocate:
        return <EditAdvocate form={form} />;
      case UserRole.Ambassador:
        return <EditAmbassador form={form} />;
    }
  };

  const handleUpdate = (
    values: UpdateValues & {
      picture?: string;
      banner?: string;
      images?: string[];
    },
  ) => {
    const { firstName, lastName, email, hideEmail, picture, banner, images, ...rest } =
      values;
    const profile: UpdateProfileInput = {
      bio: rest.bio,
      pronouns: rest.pronouns,
      dob: rest.dob,
      hideDob: rest.hideDob,
      location: rest.location,
      lat: profileToEdit.lat,
      long: profileToEdit.long,
      websiteUrl: rest.websiteUrl,
      companyName: rest.companyName,
      jobTitle: rest.jobTitle,
      facebookUrl: rest.facebookUrl,
      instagramUrl: rest.instagramUrl,
      xUrl: rest.xUrl,
      behanceUrl: rest.behanceUrl,
      linkedInUrl: rest.linkedInUrl,
      tiktokUrl: rest.tiktokUrl,
      youtubeUrl: rest.youtubeUrl,
      ...(!!picture && { picture }),
      ...(!!banner && { banner }),

      ...(user?.role === UserRole.Storyteller && {
        storyteller: {
          background: rest.background,
          memorableInfluences: rest.memorableInfluences,
          professionalPathways: rest.professionalPathways,
          currentInspirations: rest.currentInspirations,
          ongoingProjects: rest.ongoingProjects,
          impactfulChanges: rest.impactfulChanges,
          influentialMedia: rest.influentialMedia,
          legacyAspirations: rest.legacyAspirations,
          guidingAdvice: rest.guidingAdvice,
          template: rest.template,
          videoUrls,
          ...(images && {
            images: [
              ...(keepOldImages ? user.profile?.storyteller?.images ?? [] : []),
              ...images,
            ],
          }),
        },
      }),

      ...(user?.role === UserRole.Guardian && {
        guardian: {
          missionPassion: rest.missionPassion,
          guidingValues: rest.guidingValues,
          impactfulContributions: rest.impactfulContributions,
          conservationInitiatives: rest.conservationInitiatives,
          inspirationalInfluences: rest.inspirationalInfluences,
          futureLegacy: rest.futureLegacy,
          advocacyForChange: rest.advocacyForChange,
          mediaPerspectives: rest.mediaPerspectives,
          storytellingRole: rest.storytellingRole,
          storytellingFundraising: rest.storytellingFundraising,
          guidanceForOthers: rest.guidanceForOthers,
          template: rest.template,
        },
      }),

      ...(user?.role === UserRole.Advocate && {
        advocate: {
          localBeach: rest.localBeach,
          inspirations: rest.inspirations,
          coreValues: rest.coreValues,
          environmentalChange: rest.environmentalChange,
          contributions: rest.contributions,
          criticalAdvocacyChange: rest.criticalAdvocacyChange,
          activities: rest.activities,
          template: rest.template,
        },
      }),

      ...(user?.role === UserRole.Ambassador && {
        ambassador: {
          school: rest.school,
          oceanDiscovery: rest.oceanDiscovery,
          educationalPursuits: rest.educationalPursuits,
          inspirationalFigures: rest.inspirationalFigures,
          youthInitiatives: rest.youthInitiatives,
          environmentalChange: rest.environmentalChange,
          favOceanReads: rest.favOceanReads,
          futureLegacy: rest.futureLegacy,
          guidance: rest.guidance,
          template: rest.template,
        },
      }),
    };

    updateUser({
      variables: {
        userUpdate: {
          firstName,
          lastName,
          email,
          hideEmail,
          profile,
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_USERS,
          variables: {
            exclude: user?.id as string,
            page: 1,
            take: 5,
            status: [UserStatus.Approved],
          },
        },
        {
          query: GET_USERS_BY_DISTANCE,
          variables: {
            page: 1,
            take: 5,
          },
        },
      ],
    })
      .then(() => {
        message.success('Profile has been updated!');
        onSuccess();
        handleClose();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: UpdateValues) => {
        console.log('values', values);
        if (!!profileToEdit.picture) {
          uploadFile({ variables: { file: profileToEdit.picture } }).then(
            (pictureRes) => {
              if (!!profileToEdit.banner) {
                uploadFile({ variables: { file: profileToEdit.banner } }).then(
                  (bannerRes) => {
                    if (profileToEdit.storyteller?.images)
                      uploadMultipleFiles({
                        variables: { files: profileToEdit.storyteller?.images },
                      }).then((imagesRes) => {
                        handleUpdate({
                          ...values,
                          picture: pictureRes.data?.uploadFile,
                          banner: bannerRes.data?.uploadFile,
                          images: imagesRes.data?.uploadMultipleFiles,
                        });
                      });
                    else {
                      handleUpdate({
                        ...values,
                        picture: pictureRes.data?.uploadFile,
                        banner: bannerRes.data?.uploadFile,
                      });
                    }
                  },
                );
              } else {
                handleUpdate({
                  ...values,
                  picture: pictureRes.data?.uploadFile,
                });
              }
            },
          );
        } else if (!!profileToEdit.banner) {
          uploadFile({ variables: { file: profileToEdit.banner } }).then(
            (bannerRes) => {
              if (profileToEdit.storyteller?.images)
                uploadMultipleFiles({
                  variables: { files: profileToEdit.storyteller?.images },
                }).then((imagesRes) => {
                  handleUpdate({
                    ...values,
                    banner: bannerRes.data?.uploadFile,
                    images: imagesRes.data?.uploadMultipleFiles,
                  });
                });
              else {
                handleUpdate({
                  ...values,
                  banner: bannerRes.data?.uploadFile,
                });
              }
            },
          );
        } else if (profileToEdit.storyteller?.images) {
          uploadMultipleFiles({
            variables: { files: profileToEdit.storyteller?.images },
          }).then((imagesRes) => {
            handleUpdate({
              ...values,
              images: imagesRes.data?.uploadMultipleFiles,
            });
          });
        } else {
          handleUpdate(values);
        }
      })
      .catch((err) => {
        message.error('Please fill all the required fields!');
        console.log('err', err);
      });
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      width={width}
      onCancel={handleClose}
    >
      <ConfigProvider
        theme={{
          components: {
            Input: { borderRadius: 8 },
            Form: { itemMarginBottom: 0 },
          },
        }}
      >
        <Row gutter={[0, 36]}>
          <Col span={24}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <Text variant={'heading3'} color={'black10'}>
                  Edit profile
                </Text>
              </Col>
              <Col>
                <Row gutter={12}>
                  <Col>
                    <OutlinedButton
                      onClick={handleClose}
                      height={32}
                      fontSize={14}
                    >
                      Cancel
                    </OutlinedButton>
                  </Col>
                  <Col>
                    <PrimaryButton
                      height={32}
                      fontSize={14}
                      onClick={onFinish}
                      loading={uploading || uploadingMultiple || loading}
                    >
                      Save changes
                    </PrimaryButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className={'edit-profile-tabs'}>
            <Form
              form={form}
              layout={'vertical'}
              requiredMark={false}
              initialValues={{
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                hideEmail: user?.hideEmail,

                bio: user?.profile?.bio,
                pronouns: user?.profile?.pronouns,
                ...(!!user?.profile?.dob && {
                  dob: dayjs(Number(user?.profile?.dob)),
                }),
                hideDob: user?.profile?.hideDob,
                websiteUrl: user?.profile?.websiteUrl,
                companyName: user?.profile?.companyName,
                jobTitle: user?.profile?.jobTitle,
                facebookUrl: user?.profile?.facebookUrl,
                instagramUrl: user?.profile?.instagramUrl,
                xUrl: user?.profile?.xUrl,
                behanceUrl: user?.profile?.behanceUrl,
                linkedInUrl: user?.profile?.linkedInUrl,
                tiktokUrl: user?.profile?.tiktokUrl,
                youtubeUrl: user?.profile?.youtubeUrl,

                ...(user?.role === UserRole.Storyteller && {
                  background: user?.profile?.storyteller?.background,
                  memorableInfluences:
                    user?.profile?.storyteller?.memorableInfluences,
                  professionalPathways:
                    user?.profile?.storyteller?.professionalPathways,
                  currentInspirations:
                    user?.profile?.storyteller?.currentInspirations,
                  ongoingProjects: user?.profile?.storyteller?.ongoingProjects,
                  impactfulChanges:
                    user?.profile?.storyteller?.impactfulChanges,
                  influentialMedia:
                    user?.profile?.storyteller?.influentialMedia,
                  legacyAspirations:
                    user?.profile?.storyteller?.legacyAspirations,
                  guidingAdvice: user?.profile?.storyteller?.guidingAdvice,
                  template: user?.profile?.storyteller?.template,
                }),

                ...(user?.role === UserRole.Advocate && {
                  localBeach: user?.profile?.advocate?.localBeach,
                  inspirations: user?.profile?.advocate?.inspirations,
                  coreValues: user?.profile?.advocate?.coreValues,
                  environmentalChange:
                    user?.profile?.advocate?.environmentalChange,
                  contributions: user?.profile?.advocate?.contributions,
                  criticalAdvocacyChange:
                    user?.profile?.advocate?.criticalAdvocacyChange,
                  activities: user?.profile?.advocate?.activities,
                  template: user?.profile?.advocate?.template,
                }),

                ...(user?.role === UserRole.Guardian && {
                  missionPassion: user?.profile?.guardian?.missionPassion,
                  guidingValues: user?.profile?.guardian?.guidingValues,
                  impactfulContributions:
                    user?.profile?.guardian?.impactfulContributions,
                  conservationInitiatives:
                    user?.profile?.guardian?.conservationInitiatives,
                  inspirationalInfluences:
                    user?.profile?.guardian?.inspirationalInfluences,
                  futureLegacy: user?.profile?.guardian?.futureLegacy,
                  advocacyForChange: user?.profile?.guardian?.advocacyForChange,
                  mediaPerspectives: user?.profile?.guardian?.mediaPerspectives,
                  storytellingRole: user?.profile?.guardian?.storytellingRole,
                  storytellingFundraising:
                    user?.profile?.guardian?.storytellingFundraising,
                  guidanceForOthers: user?.profile?.guardian?.guidanceForOthers,
                  template: user?.profile?.guardian?.template,
                }),

                ...(user?.role === UserRole.Ambassador && {
                  school: user?.profile?.ambassador?.school,
                  oceanDiscovery: user?.profile?.ambassador?.oceanDiscovery,
                  educationalPursuits:
                    user?.profile?.ambassador?.educationalPursuits,
                  inspirationalFigures:
                    user?.profile?.ambassador?.inspirationalFigures,
                  youthInitiatives: user?.profile?.ambassador?.youthInitiatives,
                  environmentalChange:
                    user?.profile?.ambassador?.environmentalChange,
                  favOceanReads: user?.profile?.ambassador?.favOceanReads,
                  futureLegacy: user?.profile?.ambassador?.futureLegacy,
                  guidance: user?.profile?.ambassador?.guidance,
                  template: user?.profile?.ambassador?.template,
                }),
              }}
            >
              <Tabs
                items={[
                  {
                    key: 'basic',
                    label: 'Basic',
                    children: <EditBasic form={form} />,
                  },
                  {
                    key: 'additional',
                    label: 'Additional',
                    children: <EditAdditional form={form} />,
                  },
                  {
                    key: 'interview',
                    label: 'Interview & Other',
                    children: getInterview(),
                  },
                ]}
              />
            </Form>
          </Col>
        </Row>
      </ConfigProvider>
    </Modal>
  );
}

export type BasicFields = {
  firstName?: string;
  lastName?: string;
  email?: string;
  hideEmail?: boolean;
  dob?: string;
  hideDob?: boolean;
  pronouns?: string;
  location?: string;
  lat?: string;
  long?: string;
  bannerImage?: string;
};

export type AdditionalFields = {
  bio?: string;
  profilePicture?: string;
  profileBanner?: string;
  websiteUrl?: string;
  companyName?: string;
  jobTitle?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  xUrl?: string;
  behanceUrl?: string;
  linkedInUrl?: string;
  tiktokUrl?: string;
  youtubeUrl?: string;
};

export type StorytellerFields = {
  background?: string;
  memorableInfluences?: string;
  professionalPathways?: string;
  currentInspirations?: string;
  ongoingProjects?: string;
  impactfulChanges?: string;
  influentialMedia?: string;
  legacyAspirations?: string;
  guidingAdvice?: string;
  storytellerImages?: any[];
  template?: string;
};

export type GuardianFields = {
  missionPassion?: string;
  guidingValues?: string;
  impactfulContributions?: string;
  conservationInitiatives?: string;
  inspirationalInfluences?: string;
  futureLegacy?: string;
  advocacyForChange?: string;
  mediaPerspectives?: string;
  storytellingRole?: string;
  storytellingFundraising?: string;
  guidanceForOthers?: string;
};

export type AdvocateFields = {
  localBeach?: string;
  inspirations?: string;
  coreValues?: string;
  environmentalChange?: string;
  contributions?: string;
  criticalAdvocacyChange?: string;
  activities?: string[];
};

export type AmbassadorFields = {
  school?: string;
  oceanDiscovery?: string;
  educationalPursuits?: string;
  inspirationalFigures?: string;
  youthInitiatives?: string;
  environmentalChange?: string;
  favOceanReads?: string;
  futureLegacy?: string;
  guidance?: string;
};

type UpdateValues = BasicFields &
  AdditionalFields &
  StorytellerFields &
  GuardianFields &
  AdvocateFields &
  AmbassadorFields;
