import React, { useEffect, useMemo, useState } from 'react';
import { OutlinedButton, PrimaryButton, Step, Text } from '../../../components';
import { Col, Divider, Form, message, Row } from 'antd';
import {
  GET_USER,
  LoggedInUser,
  Mutation,
  MutationUpdateUserArgs,
  UPDATE_USER,
  BuildProfile,
  MutationUploadFileArgs,
  UPLOAD_FILE,
  uploadClient,
} from '../../../graphql';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  BasicInformation,
  QuestionsLayout,
  StyleYourProfile,
  ProfileValues,
  AmbassadorValues,
  UpdateAmbassadorValues,
  AmbassadorQuestions,
  ChooseTemplate,
} from '..';
import { logout } from '../../../redux/reducers';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function BuildAmbassadorProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buildProfile = useReactiveVar(BuildProfile);
  const user = useReactiveVar(LoggedInUser);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<number>(0);
  const [subStep, setSubStep] = useState<number>(1);
  const [random, setRandom] = useState<number>(0);
  const steps = useMemo(
    () => [
      'Basic information',
      'Style your profile',
      'Jr. Ambassador information',
      'Choose a template',
    ],
    [],
  );

  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [updateUser, { loading }] = useMutation<
    Mutation,
    MutationUpdateUserArgs
  >(UPDATE_USER);

  useEffect(() => {
    setRandom((random + 1) % 8);
  }, [current, subStep]);

  const handleNext = async (
    values: ProfileValues &
      AmbassadorValues & { profilePicture?: any; profileBanner?: any },
  ) => {
    delete values.profilePicture;
    delete values.profileBanner;

    if (current === 2 || current === 3) {
      BuildProfile({
        ...buildProfile,
        ambassador: { ...buildProfile.ambassador, ...values },
      });
    } else {
      BuildProfile({
        ...buildProfile,
        ...values,
        // @ts-ignore
        ...(values.dob && { dob: values.dob.toISOString() }),
      });
    }
    if (current !== 2 || (current === 2 && subStep === 8)) {
      setCurrent(current + 1);
    } else setSubStep(subStep + 1);
  };

  const handleUpdate = (values: UpdateAmbassadorValues) => {
    let { firstName, lastName, email, hideEmail, ...profile } = values;

    updateUser({
      variables: {
        userUpdate: {
          firstName,
          lastName,
          email,
          hideEmail,
          profile,
        },
        forReview: true,
      },
      refetchQueries: [{ query: GET_USER }],
    }).then(() => {
      message.success('Profile has been updated!');
    });
  };

  const onFinish = async (
    values: ProfileValues &
      AmbassadorValues & { profilePicture?: any; profileBanner?: any },
  ) => {
    if (typeof buildProfile.picture !== 'string') {
      uploadFile({ variables: { file: buildProfile.picture } }).then(
        (pictureRes) => {
          uploadFile({ variables: { file: buildProfile.banner } }).then(
            (bannerRes) => {
              handleUpdate({
                ...buildProfile,
                picture: pictureRes.data?.uploadFile,
                banner: bannerRes.data?.uploadFile,
                ambassador: {
                  ...buildProfile.ambassador,
                  ...values,
                },
              } as UpdateAmbassadorValues);
            },
          );
        },
      );
    }
  };

  const getContent = () => {
    switch (current) {
      case 0:
        return <BasicInformation form={form} />;
      case 1:
        return <StyleYourProfile form={form} />;
      case 2:
        return <AmbassadorQuestions subStep={subStep} />;
      case 3:
        return <ChooseTemplate form={form} />;
      default:
        return <Col span={24} style={{ marginTop: 26 }} />;
    }
  };

  return (
    <QuestionsLayout className={`bg-${random + 1} build-profile`}>
      <Text
        style={{ position: 'absolute', bottom: '-34px', left: '0', right: '0' }}
        onClick={() => {
          dispatch(logout());
          navigate('/login');
        }}
        className="item-wrap4 clickable logout-btn-build-profile"
        fontWeight={500}
        color={'blue6'}
        fontSize={'12px'}
      >
        Logout
      </Text>
      <Form
        form={form}
        layout={'vertical'}
        requiredMark={false}
        name="basic"
        style={{ width: '100%' }}
        onFinish={current === 3 ? onFinish : handleNext}
        autoComplete="off"
        initialValues={user}
      >
        <Row align={'top'}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Text variant={'heading3'} center>
              Build your profile
            </Text>
          </Col>
          <Col
            span={24}
            style={{ marginTop: 16, paddingBottom: 10 }}
            className="steps-titles"
          >
            <Row align={'top'} justify={'space-between'}>
              <Col style={{ width: '18%' }}>
                <Step
                  index={0}
                  current={current}
                  setCurrent={setCurrent}
                  label={steps[0]}
                />
              </Col>
              <Col style={{ width: '9%' }}>
                <Divider />
              </Col>
              <Col style={{ width: '18%' }}>
                <Step
                  index={1}
                  current={current}
                  setCurrent={setCurrent}
                  label={steps[1]}
                />
              </Col>
              <Col style={{ width: '9%' }}>
                <Divider />
              </Col>
              <Col style={{ width: '18%' }}>
                <Step
                  index={2}
                  current={current}
                  setCurrent={setCurrent}
                  label={steps[2]}
                />
              </Col>
              <Col style={{ width: '9%' }}>
                <Divider />
              </Col>
              <Col style={{ width: '18%' }}>
                <Step
                  index={3}
                  current={current}
                  setCurrent={setCurrent}
                  label={steps[3]}
                />
              </Col>
            </Row>
          </Col>
          <Row
            className={'scroll-container'}
            style={{
              maxHeight: '72vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
              width: '100%',
              paddingBottom: '120px',
            }}
          >
            {getContent()}
            <Col
              className="navigate-btn"
              span={24}
              style={{
                position: 'absolute',
                bottom: 0,
                marginTop: '36px',
                width: '100%',
              }}
            >
              <Row gutter={[20, 12]} style={{ background: 'white' }}>
                <Col span={current === 2 ? 24 : 0}>
                  <Text variant={'smMedium'} color={'black8'}>
                    Question {subStep}{' '}
                    <Text variant={'smMedium'} color={'black3'}>
                      of 8
                    </Text>
                  </Text>
                </Col>
                <Col span={current === 0 ? 0 : 6}>
                  <OutlinedButton
                    block
                    onClick={() => {
                      if (current !== 2 || (current === 2 && subStep === 1)) {
                        setCurrent(current - 1);
                      } else {
                        setSubStep(subStep - 1);
                      }
                    }}
                  >
                    Back
                  </OutlinedButton>
                </Col>
                <Col span={current === 0 ? 24 : 18}>
                  <PrimaryButton
                    block
                    htmlType={'submit'}
                    loading={uploading || loading}
                  >
                    {current === 3 ? 'Submit my profile' : 'Next'}
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Form>
    </QuestionsLayout>
  );
}
