import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Radio, Row } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { BuildProfile } from '../../graphql';
import { LocationSearch } from '../../components';
import { colors } from '../../utils';

type FieldType = {
  firstName: string;
  lastName: string;
  email: string;
  hideEmail: boolean;
  dob: string;
  hideDob: boolean;
  pronouns: string;
  location: string;
};

export default function BasicInformation({ form }: { form: FormInstance }) {
  const buildProfile = useReactiveVar(BuildProfile);

  return (
    <Col span={24} style={{ marginTop: 36 }}>
      <Row gutter={[10, 20]}>
        <Col span={12}>
          <Form.Item<FieldType>
            label="First name"
            name="firstName"
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder="First name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<FieldType>
            label="Last name"
            name="lastName"
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder="Last name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item<FieldType>
            label="Contact & Account email"
            name="email"
            rules={[{ required: true, message: '' }]}
          >
            <Input type="email" placeholder="Email address" />
          </Form.Item>
          <Form.Item<FieldType>
            name="hideEmail"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox style={{float: 'left', marginTop: 8, color: colors.black5}}>Hide my email from users</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item<FieldType>
            label="Date of birth"
            name="dob"
          >
            <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} />
          </Form.Item>
          <Form.Item<FieldType>
            name="hideDob"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox style={{float: 'left', marginTop: 8, color: colors.black5}}>Hide my date of birth from users</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Form.Item<FieldType>
            label="What are your preferred pronouns?"
            name="pronouns"
            rules={[{ required: true, message: 'Pronouns are required' }]}
          >
            <Radio.Group>
              <Row gutter={[0, 6]}>
                <Col span={24}>
                  <Radio value={'he/him/his'}>he/him/his</Radio>
                </Col>
                <Col span={24}>
                  <Radio value={'she/her/hers'}>she/her/hers</Radio>
                </Col>
                <Col span={24}>
                  <Radio value={'they/them/theirs'}>they/them/theirs</Radio>
                </Col>
                <Col span={24}>
                  <Radio value={'other'}>Other</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24} style={{ textAlign: 'left' }} className="questionwidth">
          <Form.Item<FieldType>
            label="Where are you based?"
            name="location"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <LocationSearch
              options={{ types: ['(cities)'] }}
              location={buildProfile.location ?? ''}
              setLocation={({ lat, long, locationString }) => {
                form.setFieldsValue({
                  location: locationString,
                });
                BuildProfile({
                  ...buildProfile,
                  location: locationString,
                  lat: lat.toString(),
                  long: long.toString(),
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
}
